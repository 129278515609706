import CopperServicesContainer from '../containers/Services/CopperIndex';

const CopperServices = () => {

  return (
    <>
      <CopperServicesContainer />
    </>
  );
}

export default CopperServices;

