import FiberServicesContainer from "../containers/Services/FiberIndex";

const FiberServices = () => {
  return (
    <>
      <FiberServicesContainer />
    </>
  );
};

export default FiberServices;
