import ICTServicesContainer from '../containers/Services/ICT';

const ICTServices = () => {

  return (
    <>
      <ICTServicesContainer />
    </>
  );
}

export default ICTServices;

