import PricingContainer from '../containers/Pricing'

const Pricing = () => {

  return (
    <>
      <PricingContainer />
    </>
  );
}

export default Pricing;

