import AboutContainer from '../containers/About'

const About = () => {

  return (
    <>
      <AboutContainer />
    </>
  );
}

export default About;

