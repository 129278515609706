import ServicesContainer from '../containers/Services'

const Services = () => {

  return (
    <>
      <ServicesContainer />
    </>
  );
}

export default Services;

