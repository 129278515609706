const ContactContent = [
	{
		nameInput:'name',
		title:'Name',
		lastItem:true
	},
	{
		nameInput:'email',
		title:'Email',
		lastItem:true
	},
	{
		nameInput:'subject',
		title:'Subject',
		lastItem:false
	}
]

export default ContactContent