import LoginContainer from "../containers/Login/Login";
import SecLogin from "../layouts/Header/SecLogin";


const Login = () => {

  return (
    <>

   <LoginContainer/>
    </>
  );
}

export default Login;

