import FaqContainer from '../containers/Faq'

const Faq = () => {

  return (
    <>
      <FaqContainer />
    </>
  );
}

export default Faq;

